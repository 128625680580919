<template>
    <b-modal
      v-model="value"
      title="Add/Edit Header item"
      ref="item-modal"
      id="item-modal"
    >
      <b-form @submit.prevent="save">
        <b-form-checkbox v-model="item.active" name="active" class="content-switch" switch><b>Active</b></b-form-checkbox>
        <b-tabs content-class="mt-3" nav-class="no-padding">
          <b-tab v-for="(language, index) in languages" :key="index">
            <template #title>
              <country-flag :country="isoLanguage(language)" size="normal" />
            </template>
            <b-form-group label="Title">
              <b-form-input
                name="title"
                v-validate="'required|min:3|max:50'"
                v-model="item.title[language]"
              ></b-form-input>
              <div v-if="submitted && errors.has('title')" class="alert-danger">
                {{ errors.first("title") }}
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs>
        <b-form-group label="Link">
          <b-form-input v-model="item.link" />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
        <b-button type="submit" @click="save()">Save</b-button>
        <b-button @click="value = false">Close</b-button>
      </template>
    </b-modal>
</template>

<script>

  export default {
    props: ["value", "item", "items", "index"],
    data() {
      return {
        submitted: false,
        successful: false,
      };
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      },
    },
    methods: {
      isoLanguage(language) {
        let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
        if (iso == "en") {
          iso = "us";
        }
        return iso;
      },
      async save() {
        this.message = "";
        this.submitted = true;
        this.$validator.validateAll().then((isValid) => {
          if (!isValid) {
            return;
          }
          if (this.index !== null) {
            this.items[this.index] = this.item;
            this.value = false;
          } else {
            this.items.push(this.item);
            this.value = false;
          }
          this.$store.dispatch("Header/update", this.items);
          this.$store.dispatch("Header/fetchAll");
        });
      },
    },
    computed: {
      languages() {
        return this.$store.state.Languages.all;
      },
    }
  }
</script>